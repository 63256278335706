* {
  image-rendering: pixelated;
}

#break-donations {
  z-index: 200;
  background: url("donation-bg.875fe6d2.png");
  width: 1920px;
  height: 230px;
  position: absolute;
  top: 905px;
}

#break-donations .donation-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

#break-donations .donation-container .donation-total {
  color: #0f2e0d;
  font-family: teko-medium;
  font-size: 120px;
  position: relative;
  top: 9px;
}

#break-donations .donation-container .donation-reason {
  color: #f3f3f3;
  text-transform: uppercase;
  font-family: teko-medium;
  font-size: 36px;
  position: relative;
  top: -29px;
}

#break-donations .donator-leaderboard, #break-donations .donation-leaderboard {
  position: absolute;
  top: 50px;
  left: 1200px;
}

#break-donations .donator-leaderboard .title, #break-donations .donation-leaderboard .title {
  color: #f3f3f3;
  background-color: #9f1b1b;
  width: 300px;
  height: 36px;
  margin-bottom: 6px;
  padding-left: 5px;
  font-family: teko;
  font-size: 32px;
}

#break-donations .donator-leaderboard .leaderboard-1, #break-donations .donation-leaderboard .leaderboard-1 {
  margin-left: 18px;
}

#break-donations .donator-leaderboard .leaderboard-2, #break-donations .donation-leaderboard .leaderboard-2 {
  margin-left: 38px;
}

#break-donations .donator-leaderboard .leaderboard-3, #break-donations .donation-leaderboard .leaderboard-3 {
  margin-left: 58px;
}

#break-donations .donator-leaderboard .leaderboard, #break-donations .donation-leaderboard .leaderboard {
  color: #f3f3f3;
  height: 31px;
  font-family: teko;
  font-size: 26px;
  display: flex;
}

#break-donations .donator-leaderboard .leaderboard .position, #break-donations .donation-leaderboard .leaderboard .position {
  color: #9f1b1b;
  width: 15px;
  font-family: teko-bold;
  font-size: 26px;
  font-style: italic;
}

#break-donations .donator-leaderboard .leaderboard .name, #break-donations .donation-leaderboard .leaderboard .name {
  color: #f3f3f3;
  margin-left: 9px;
}

#break-donations .donator-leaderboard .leaderboard .amount, #break-donations .donation-leaderboard .leaderboard .amount {
  color: #f3f3f3;
  margin-left: 6px;
}

#break-donations .donator-leaderboard .title {
  background-color: #0c4e52;
}

#break-donations .donator-leaderboard .leaderboard .position {
  color: #0c4e52;
}

#break-next {
  z-index: 200;
  background: url("schedule-bg.4bb2a8dd.png");
  width: 1080px;
  height: 805px;
  position: absolute;
  top: 95px;
  left: 840px;
}

#break-next .next-run {
  opacity: 0;
  width: 1040px;
  height: 160px;
  padding-top: 22px;
  padding-left: 21px;
  position: absolute;
  top: 20px;
  left: 26px;
}

#break-next .next-run .game {
  color: #1d0d2b;
  text-transform: uppercase;
  background-color: #e2881d;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 998px;
  height: 80px;
  padding-top: 4px;
  font-family: teko;
  font-size: 52px;
  display: flex;
}

#break-next .next-run .game .when {
  margin-left: 10px;
}

#break-next .next-run .game .chevron {
  background-image: url("schedule-chevron-dark.33ee3250.png");
  width: 20px;
  height: 20px;
  margin-left: 20px;
  position: relative;
  top: -2px;
}

#break-next .next-run .game .what {
  flex-wrap: wrap;
  align-items: center;
  max-height: 75px;
  display: flex;
}

#break-next .next-run .game .what .name {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 770px;
  margin-left: 20px;
  overflow: hidden;
}

#break-next .next-run .game .what .category {
  text-transform: none;
  margin-left: 20px;
  font-family: teko-light;
}

#break-next .next-run .game .what .category.alternate {
  opacity: 0;
  position: absolute;
}

#break-next .next-run .who {
  color: #f3f3f3;
  margin-top: 7px;
  margin-left: 8px;
  font-family: teko-light;
  font-size: 30px;
}

#break-next .next-run .who .name, #break-next .next-run .who .elapsed {
  color: #e2881d;
  font-family: teko;
}

#break-next .future-runs {
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 193px;
  left: 20px;
}

#break-next .future-runs .game {
  opacity: 0;
  width: 1037px;
  height: 118px;
  display: flex;
}

#break-next .future-runs .game .when {
  text-transform: uppercase;
  color: #e2881d;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  margin-left: 9px;
  display: flex;
}

#break-next .future-runs .game .when .in {
  height: 30px;
  font-family: teko;
  font-size: 28px;
}

#break-next .future-runs .game .when .in:empty {
  position: absolute;
}

#break-next .future-runs .game .when .estimate {
  text-align: center;
  height: 44px;
  font-family: teko-semibold;
  font-size: 44px;
  position: relative;
  top: -7px;
  overflow-y: hidden;
}

#break-next .future-runs .game .chevron {
  background-image: url("schedule-chevron-light.d7caf234.png");
  width: 20px;
  height: 40px;
  position: relative;
  top: 39px;
}

#break-next .future-runs .game .data {
  flex-direction: column;
  place-content: flex-start center;
  align-items: flex-start;
  margin-left: 29px;
  display: flex;
}

#break-next .future-runs .game .data .what {
  filter: drop-shadow(0 0 2px #1115);
  color: #f3f3f3;
  font-size: 38px;
  display: flex;
}

#break-next .future-runs .game .data .what .name {
  text-transform: uppercase;
  font-family: teko;
  position: relative;
  top: 4px;
}

#break-next .future-runs .game .data .what .category {
  margin-left: 15px;
  font-family: teko-light;
  position: relative;
  top: 4px;
}

#break-next .future-runs .game .data .who {
  filter: drop-shadow(0 0 2px #1115);
  color: #f3f3f3;
  font-family: teko-light;
  font-size: 24px;
  position: relative;
  top: -8px;
}

#break-next .future-runs .game .data .who .name, #break-next .future-runs .game .data .who .elapsed {
  color: #e2881d;
  font-family: teko;
}

#break-next .future-runs .game .data .who.hide {
  position: absolute;
}

#break-next .future-runs .game .end {
  justify-content: center;
  align-items: center;
  width: 1037px;
  height: 118px;
  display: flex;
  position: absolute;
}

#break-next .future-runs .game .end span {
  color: #494949;
  text-transform: uppercase;
  font-family: press-start;
  font-size: 24px;
}

.incentive-break {
  z-index: 200;
  background: url("media-scroll-backdrop.9d340472.png");
  width: 816px;
  height: 376px;
  position: absolute;
  top: 1150px;
}

.incentive-break .incentive-frame {
  background: url("media-scroll-frame.f16edc01.png");
  width: 100%;
  height: 100%;
}

.incentive-break .incentive-frame .break-opacity {
  width: 100%;
  height: 100%;
  position: absolute;
}

.incentive-break .incentive-frame .song-container {
  z-index: 200;
  justify-content: center;
  align-items: center;
  width: 715px;
  height: 341px;
  display: flex;
  position: absolute;
  top: 25px;
  left: 50px;
}

.incentive-break .incentive-frame .song-container .song-ascii {
  column-count: 40;
  color: #91702a;
  resize: none;
  background-color: #0000;
  border: none;
  width: 330px;
  height: 320px;
  font-family: monospace;
  font-weight: 1000;
  overflow: hidden;
}

.incentive-break .incentive-frame .ad-container {
  opacity: 0;
  z-index: 200;
  background-position: center;
  background-size: cover;
  width: 715px;
  height: 341px;
  position: absolute;
  top: 25px;
  left: 50px;
}

.incentive-break .incentive-frame .incentive-container {
  opacity: 0;
  flex-direction: column;
  width: 765px;
  height: 100%;
  padding-top: 25px;
  padding-left: 50px;
  display: flex;
}

.incentive-break .incentive-frame .incentive-container .incentive-title {
  color: #f3f3f3;
  background: url("media-scroll-highlight.3a6ecdb1.png") bottom no-repeat;
  padding-left: 10px;
  font-size: 39px;
  display: flex;
}

.incentive-break .incentive-frame .incentive-container .incentive-title .title {
  text-shadow: 0 0 4px #000;
  white-space: nowrap;
  width: 247px;
}

.incentive-break .incentive-frame .incentive-container .incentive-title .data {
  margin-top: 10px;
  margin-left: 10px;
  line-height: 35px;
}

.incentive-break .incentive-frame .incentive-container .incentive-title .data .game-name {
  text-shadow: 0 0 4px #000;
  color: #e2881d;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 440px;
  font-size: 39px;
  overflow: hidden;
}

.incentive-break .incentive-frame .incentive-container .incentive-title .data .description {
  text-shadow: 0 0 4px #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 445px;
  font-family: teko-light;
  font-size: 33px;
  overflow: hidden;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data {
  color: #f3f3f3;
  place-content: center;
  align-items: center;
  height: 74%;
  display: flex;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-fixed {
  display: none;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-fixed .graph {
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 230px;
  display: flex;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-fixed .graph .circle {
  width: 230px;
  height: 230px;
  position: absolute;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-fixed .graph .totals {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  line-height: 36px;
  display: flex;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-fixed .graph .totals .percent {
  color: #e2881d;
  font-size: 38px;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-bars {
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 260px;
  display: none;
  position: relative;
  left: 0;
  overflow: hidden;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-bars .no-options {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: none;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-bars .no-options .text {
  font-size: 30px;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-bars .bar {
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-bars .bar.bar-3 {
  margin-left: -390px;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-bars .bar .moving {
  background: url("media-scroll-barra.be491940.png");
  justify-content: flex-end;
  align-items: center;
  width: 480px;
  height: 60px;
  display: flex;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-bars .bar .moving .total {
  color: #e2881d;
  padding-right: 11px;
  font-size: 24px;
}

.incentive-break .incentive-frame .incentive-container .incentive-break-data .incentive-bars .bar .name {
  text-transform: uppercase;
  padding-left: 10px;
  font-size: 27px;
}

#media-player {
  position: absolute;
  top: 840px;
}
/*# sourceMappingURL=repo.8abaea31.css.map */
